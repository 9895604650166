<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :key="key"
    :to="to ? to : undefined"
    class="group h-12 lg:h-16"
    :class="[
      variant !== 'unstyled' && classList,
      !icon && 'px-6',
      variant === 'solid' && icon && 'pl-6 pr-1 lg:pr-3',
      variant === 'outline' && icon && 'pl-6 pr-1',
      variant === 'solid' ? 'text-xl' : 'text-lg'
    ]"
    :disabled="!to && disabled"
    @click="onClick"
  >
    {{ label }}
    <slot v-if="!label" />
    <span
      v-if="icon"
      class="align-center inline-flex justify-center rounded-full transition-colors duration-300 ml-3 lg:ml-6"
      :class="[
        iconCircleClass,
        variant === 'outline' ? 'h-8 w-8 lg:h-10 lg:w-10' : 'h-10 w-10 lg:h-12 lg:w-12',
        disabled && variant === 'solid' && '!bg-white',
        disabled && variant === 'outline' && '!bg-disabled',
        color && '!bg-blue'
      ]"
    >
      <Icon
        class="group-hover:animate-wiggle"
        :class="iconClass"
        :name="
          typeof icon === 'boolean' ? 'arrow-right' : typeof icon === 'string' ? icon : icon.name
        "
        :size="typeof icon === 'boolean' || typeof icon === 'string' ? 'sm' : icon.size"
        :color="iconColor"
      />
    </span>
  </component>
</template>

<script setup lang="ts">
import type { IconSize, IconName } from '~/components/media/Icon.vue'
import { theme } from '#tailwind-config'
const NuxtLink = resolveComponent('NuxtLink')

type ButtonVariant = 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled'

interface Props {
  disabled?: boolean
  icon?:
    | {
        color?: string
        name: IconName
        size?: IconSize
      }
    | boolean
  variant?: ButtonVariant
  label?: string | null
  loading?: boolean
  to?: string | object | undefined | null
  prevent?: boolean
  color?: 'default' | 'blue' | 'gray'
  iconClass?: string
  iconCircleClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  variant: 'solid',
  loading: false,
  prevent: false
})
const key = ref(0)

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

const $style = useCssModule()

const iconColor = computed(() => {
  if (typeof props.icon === 'string' || typeof props.icon === 'boolean') {
    if (props.disabled) {
      return props.variant === 'solid' ? theme.colors.disabled : 'white'
    }

    return 'white'
  } else {
    return props.icon?.color
  }
})

const classList = computed(() => {
  return [
    // Element
    $style.button,
    {
      // Style
      [$style.solid]: props.variant === 'solid',
      [$style.outline]: props.variant === 'outline',
      [$style.ghost]: props.variant === 'ghost',
      [$style.link]: props.variant === 'link',

      // Size
      [$style.medium]: props.size === 'md',
      [$style.large]: props.size === 'lg',

      // Color
      [$style.blue]: props.variant === 'solid' && props.color === 'blue',
      '!bg-blue-900 !text-white hover:!bg-opacity-70':
        props.variant === 'solid' && props.color === 'blue',

      [$style.gray]: props.variant === 'solid' && props.color === 'gray',

      '!bg-light-gray': props.variant === 'solid' && props.color === 'gray',

      '!border-blue': props.variant === 'outline' && props.color === 'blue',

      // State
      [$style.disabled]: props.disabled || props.loading
    }
  ]
})

function onClick(event: MouseEvent) {
  if (props.disabled || props.loading || props.prevent) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
}

onMounted(() => {
  key.value += 1
})
</script>

<style lang="postcss" module>
.button {
  --color-primary: theme('colors.orange.DEFAULT');
  --hover-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @apply relative z-1 inline-flex min-w-fit cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap rounded-full leading-none no-underline transition-colors duration-300;

  &:before {
    @apply absolute bottom-0 left-0 -z-1 h-full w-0 rounded-full bg-[var(--hover-color)] transition-all duration-300 content-[''];
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}

/* Solid */

.solid {
  --hover-color: theme('colors.orange.300');

  @apply bg-orange text-blue-800 active:bg-orange-600;

  > span {
    @apply bg-blue-800;
  }
}

/* Outline */

.outline {
  @apply border-4 border-orange bg-transparent text-blue-800;

  > span {
    @apply bg-orange;
  }

  &:hover {
    @apply border-orange-300;

    > span {
      @apply bg-orange-300;
    }
  }

  &:active {
    @apply border-orange bg-orange;

    > span {
      @apply bg-white;

      svg use {
        stroke: #f39200;
      }
    }
  }
}

/* State */
.disabled {
  @apply pointer-events-none cursor-default border-disabled;

  &.solid {
    @apply bg-disabled text-white;
  }

  &.outline {
    @apply bg-transparent text-blue-600;
  }
}

/* Colors */
.blue {
  --hover-color: theme('colors.blue.300');
}

.gray {
  --hover-color: theme('colors.light-gray.200');
}
</style>
