import { default as _91slug_93RPYB4NvKKjMeta } from "/opt/build/repo/pages/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91slug_93meYoKtYfMgMeta } from "/opt/build/repo/pages/novice/[slug].vue?macro=true";
import { default as _91slug_93r50nEY5Tp5Meta } from "/opt/build/repo/pages/program-product/[slug].vue?macro=true";
import { default as _91category_93qFv1lSz1KlMeta } from "/opt/build/repo/pages/trgovina/[category].vue?macro=true";
import { default as _91slug_939LwpQNIKpxMeta } from "/opt/build/repo/pages/trgovina/izdelki/[slug].vue?macro=true";
export default [
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___sl",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___en",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/en/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___sl",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/en",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93meYoKtYfMgMeta?.name ?? "novice-slug___sl",
    path: _91slug_93meYoKtYfMgMeta?.path ?? "/novice/:slug()",
    meta: _91slug_93meYoKtYfMgMeta || {},
    alias: _91slug_93meYoKtYfMgMeta?.alias || [],
    redirect: _91slug_93meYoKtYfMgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/novice/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93meYoKtYfMgMeta?.name ?? "novice-slug___en",
    path: _91slug_93meYoKtYfMgMeta?.path ?? "/en/news/:slug()",
    meta: _91slug_93meYoKtYfMgMeta || {},
    alias: _91slug_93meYoKtYfMgMeta?.alias || [],
    redirect: _91slug_93meYoKtYfMgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/novice/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r50nEY5Tp5Meta?.name ?? "program-product-slug___sl",
    path: _91slug_93r50nEY5Tp5Meta?.path ?? "/program-product/:slug()",
    meta: _91slug_93r50nEY5Tp5Meta || {},
    alias: _91slug_93r50nEY5Tp5Meta?.alias || [],
    redirect: _91slug_93r50nEY5Tp5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/program-product/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r50nEY5Tp5Meta?.name ?? "program-product-slug___en",
    path: _91slug_93r50nEY5Tp5Meta?.path ?? "/en/program-product/:slug()",
    meta: _91slug_93r50nEY5Tp5Meta || {},
    alias: _91slug_93r50nEY5Tp5Meta?.alias || [],
    redirect: _91slug_93r50nEY5Tp5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/program-product/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91category_93qFv1lSz1KlMeta?.name ?? "trgovina-category___sl",
    path: _91category_93qFv1lSz1KlMeta?.path ?? "/trgovina/:category()",
    meta: _91category_93qFv1lSz1KlMeta || {},
    alias: _91category_93qFv1lSz1KlMeta?.alias || [],
    redirect: _91category_93qFv1lSz1KlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/trgovina/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93qFv1lSz1KlMeta?.name ?? "trgovina-category___en",
    path: _91category_93qFv1lSz1KlMeta?.path ?? "/en/shop/:category()",
    meta: _91category_93qFv1lSz1KlMeta || {},
    alias: _91category_93qFv1lSz1KlMeta?.alias || [],
    redirect: _91category_93qFv1lSz1KlMeta?.redirect,
    component: () => import("/opt/build/repo/pages/trgovina/[category].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939LwpQNIKpxMeta?.name ?? "trgovina-izdelki-slug___sl",
    path: _91slug_939LwpQNIKpxMeta?.path ?? "/trgovina/izdelki/:slug()",
    meta: _91slug_939LwpQNIKpxMeta || {},
    alias: _91slug_939LwpQNIKpxMeta?.alias || [],
    redirect: _91slug_939LwpQNIKpxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/trgovina/izdelki/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939LwpQNIKpxMeta?.name ?? "trgovina-izdelki-slug___en",
    path: _91slug_939LwpQNIKpxMeta?.path ?? "/en/shop/products/:slug()",
    meta: _91slug_939LwpQNIKpxMeta || {},
    alias: _91slug_939LwpQNIKpxMeta?.alias || [],
    redirect: _91slug_939LwpQNIKpxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/trgovina/izdelki/[slug].vue").then(m => m.default || m)
  }
]