<template>
  <svg
    :key="key"
    aria-hidden="true"
    class="inline-block self-center overflow-hidden fill-current stroke-current stroke-0"
    :class="[classSize]"
  >
    <use :xlink:href="`${symbolDefs}#icon-${name}`" :fill="color" :stroke="color" />
  </svg>
</template>

<script setup lang="ts">
import symbolDefs from '~/assets/images/icons/symbol-defs.svg'

export type IconName =
  | 'arrow-move-down'
  | 'arrow-right-wide'
  | 'arrow-right'
  | 'attachment'
  | 'bell'
  | 'blur'
  | 'book'
  | 'brightness'
  | 'business-progress'
  | 'calendar-edit'
  | 'calendar'
  | 'camera-focus'
  | 'chat-bubbles'
  | 'chevron-wide'
  | 'chevron'
  | 'close'
  | 'coins'
  | 'crown'
  | 'dots'
  | 'expand-square'
  | 'facebook'
  | 'filter'
  | 'format-bullets'
  | 'gift'
  | 'glasses'
  | 'happy-face'
  | 'headset'
  | 'heart-line'
  | 'home'
  | 'humidity'
  | 'information'
  | 'instagram'
  | 'invisible-2'
  | 'invisible'
  | 'mail'
  | 'megaphone'
  | 'menu'
  | 'new-file'
  | 'padlock'
  | 'paint-palette'
  | 'parachute'
  | 'payment'
  | 'picture-folder'
  | 'play'
  | 'plus-rounded'
  | 'plus'
  | 'puzzle'
  | 'recycle'
  | 'recycle-bin'
  | 'reload'
  | 'search'
  | 'shield'
  | 'shipping-box'
  | 'shopping-cart'
  | 'shrink-diagonal'
  | 'synchronize-warning'
  | 'transfer-van'
  | 'trophy'
  | 'umbrella'
  | 'upload-circle'
  | 'user-protection'
  | 'user-validate'
  | 'visible'
  | 'warning-octagon'
  | 'warning-triangle'
  | 'wind-flow-2'
  | 'wind-flow'
  | 'wrench'
  | 'minus'
  | 'swipe'
  | 'info'
  | 'checked'

export type IconSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

interface Props {
  color?: string
  name: IconName
  size?: IconSize
}

const props = withDefaults(defineProps<Props>(), {
  color: 'black',
  size: 'md'
})

const key = ref(0)

const classSize = computed(() => {
  let classList
  switch (props.size) {
    case '2xs':
      classList = 'w-3 h-3'
      break
    case 'xs':
      classList = 'w-4 h-4'
      break
    case 'sm':
      classList = 'w-5 h-5'
      break
    case 'lg':
      classList = 'w-8 h-8'
      break
    case 'xl':
      classList = 'w-10 h-10'
      break
    case '2xl':
      classList = 'w-16 h-16'
      break
    default:
      classList = 'w-6 h-6'
  }
  return classList
})

onMounted(() => {
  key.value += 1
})
</script>
