import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["app","articles","article","Button","CallToAction","Image","Table","VideoEmbed","Faq","ProgramCategory","SectionHero","SectionHero2","SectionHero3","SectionShopHero","SectionTitle","SectionThankYou","index","page","programCategories","programProducts","programProduct","SectionCta","SectionBody","SectionArticles","SectionImageGallery","SectionContactForm","SectionFeatures","SectionLogos","SectionMediaVertical","SectionMediaHorizontal","SectionRelatedArticles","SectionTestimonials","SectionVideo","SectionMediafull","SectionFaq","SectionSegments","SectionProductList","SectionPosition","SectionCatalog","SectionPrograms","SectionStacks","SectionMultiple","SectionPrograme","SectionInquiryForm","SectionShopProductList","SectionRelatedShopProductsList","segments","shopProducts","shopProduct","shopCategory"]}
export const GqlApp = (...params) => useGql()('app', ...params)
export const GqlArticle = (...params) => useGql()('article', ...params)
export const GqlArticles = (...params) => useGql()('articles', ...params)
export const GqlIndex = (...params) => useGql()('index', ...params)
export const GqlPage = (...params) => useGql()('page', ...params)
export const GqlProgramCategories = (...params) => useGql()('programCategories', ...params)
export const GqlProgramProduct = (...params) => useGql()('programProduct', ...params)
export const GqlProgramProducts = (...params) => useGql()('programProducts', ...params)
export const GqlSegments = (...params) => useGql()('segments', ...params)
export const GqlShopCategory = (...params) => useGql()('shopCategory', ...params)
export const GqlShopProduct = (...params) => useGql()('shopProduct', ...params)
export const GqlShopProducts = (...params) => useGql()('shopProducts', ...params)