export default function () {
  const isLocked = useState<boolean>('bodyLock', () => false)

  function bodyLock() {
    isLocked.value = true
    document.body.classList.add('overflow-hidden')
  }

  function bodyUnlock() {
    isLocked.value = false
    document.body.classList.remove('overflow-hidden')
  }

  return {
    bodyLock,
    bodyUnlock
  }
}
