import { defineNuxtPlugin } from '#app/nuxt'
import { LazySectionArticles, LazySectionRelatedArticles, LazySectionFaq, LazySectionContactForm, LazySectionImageGallery, LazySectionLogos, LazySectionMediaFull, LazySectionMediaHorizontal, LazySectionMediaVertical, LazySectionVideo, LazySectionProductList, LazySectionRelatedShopProductsList, LazySectionShopProductList, LazySectionBody, LazySectionCatalog, LazySectionCta, LazySectionHero, LazySectionHero2, LazySectionHero3, LazySectionProgrameCta, LazySectionPrograms, LazySectionShopHero, LazySectionStacks, LazySectionThankYou, LazySectionTitle, LazySectionFeatures, LazySectionInquiryForm, LazySectionMultiple, LazySectionPosition, LazySectionSegments, LazySectionTestimonials } from '#components'
const lazyGlobalComponents = [
  ["SectionArticles", LazySectionArticles],
["SectionRelatedArticles", LazySectionRelatedArticles],
["SectionFaq", LazySectionFaq],
["SectionContactForm", LazySectionContactForm],
["SectionImageGallery", LazySectionImageGallery],
["SectionLogos", LazySectionLogos],
["SectionMediaFull", LazySectionMediaFull],
["SectionMediaHorizontal", LazySectionMediaHorizontal],
["SectionMediaVertical", LazySectionMediaVertical],
["SectionVideo", LazySectionVideo],
["SectionProductList", LazySectionProductList],
["SectionRelatedShopProductsList", LazySectionRelatedShopProductsList],
["SectionShopProductList", LazySectionShopProductList],
["SectionBody", LazySectionBody],
["SectionCatalog", LazySectionCatalog],
["SectionCta", LazySectionCta],
["SectionHero", LazySectionHero],
["SectionHero2", LazySectionHero2],
["SectionHero3", LazySectionHero3],
["SectionProgrameCta", LazySectionProgrameCta],
["SectionPrograms", LazySectionPrograms],
["SectionShopHero", LazySectionShopHero],
["SectionStacks", LazySectionStacks],
["SectionThankYou", LazySectionThankYou],
["SectionTitle", LazySectionTitle],
["SectionFeatures", LazySectionFeatures],
["SectionInquiryForm", LazySectionInquiryForm],
["SectionMultiple", LazySectionMultiple],
["SectionPosition", LazySectionPosition],
["SectionSegments", LazySectionSegments],
["SectionTestimonials", LazySectionTestimonials],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
