
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "e5e34443-4a5b-4b20-bced-70b5b6f29eb0"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/build/repo/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
