<template>
  <NuxtLink
    class="block w-fit text-center"
    :title="$t('logoLink')"
    :to="localePath('/')"
    @click="menuHide"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 149 26"
      fill="none"
    >
      <path
        d="M53.8685 12.4556C53.8685 16.4292 51.4939 17.8713 46.0434 17.8713C40.5929 17.8713 38.2182 16.4292 38.2182 12.4556V3.71973H32.7407V13.626C32.7407 19.9669 37.9215 22.5794 46.0434 22.5794C54.1653 22.5794 59.346 19.9669 59.346 13.626V3.71973H53.8685V12.4556Z"
        :fill="color"
      />
      <path
        d="M82.4015 12.755H69.9892V8.45511H82.4015C84.2094 8.45511 84.965 8.97206 84.965 10.6321C84.965 12.1563 84.2094 12.755 82.4015 12.755ZM90.6314 10.1969C90.6314 5.32535 87.4745 3.71973 82.9412 3.71973H64.5117V22.2801H69.9893L69.9892 16.8918H79.3309L85.9417 22.2801H92.8225L85.6647 16.3043C88.6046 15.5752 90.6314 13.6152 90.6314 10.1969Z"
        :fill="color"
      />
      <path
        d="M130.732 17.5447V3.71973H125.255V22.2801L149 22.28V17.5447H130.732Z"
        :fill="color"
      />
      <path
        d="M5.47185 10.6868C6.23568 8.0507 8.80407 7.47316 14.1732 7.47316C20.3342 7.47316 22.5489 8.25657 23.1554 10.6868H5.47185ZM14.354 3.33643C5.2066 3.33643 0 6.10773 0 13C0 20.0015 4.7004 22.7 14.8601 22.7C19.5606 22.7 24.8394 22.08 28.0933 21.2776L26.9726 17.3028C24.8187 17.6844 20.6513 18.57 15.3303 18.5633C9.03345 18.5554 6.20376 17.7258 5.42865 14.8236H28.3888L28.455 14.3128C28.5273 13.8751 28.5994 12.5987 28.5994 11.8331C28.5994 5.56082 22.8508 3.33643 14.354 3.33643Z"
        :fill="color"
      />
      <path
        d="M107.792 21.2645C103.266 21.2645 99.5974 17.5644 99.5974 13C99.5974 8.43558 103.266 4.73548 107.792 4.73548C112.317 4.73548 115.986 8.43558 115.986 13C115.986 17.5644 112.317 21.2645 107.792 21.2645ZM107.792 0C100.673 0 94.9021 5.82035 94.9021 13C94.9021 20.1797 100.673 26 107.792 26C114.91 26 120.681 20.1797 120.681 13C120.681 5.82035 114.91 0 107.792 0Z"
        :fill="color"
      />
    </svg>
  </NuxtLink>
</template>

<script setup lang="ts">
const { menuHide } = useMenu()
const localePath = useLocalePath()

defineProps({
  width: {
    type: Number,
    default: 150
  },
  height: {
    type: Number,
    default: 28
  },
  color: {
    type: String,
    default: 'white'
  }
})
</script>
