export const useMenuVisible = () => useState('menu', () => false)

export default function () {
  const { bodyLock, bodyUnlock } = useBodyLock()

  // Main Menu
  const isVisible = useState('menu')

  function menuHide() {
    isVisible.value = false
    bodyUnlock()
  }

  function menuShow() {
    isVisible.value = true
    bodyLock()
  }

  function menuToggle() {
    if (!isVisible.value) {
      menuShow()
    } else {
      menuHide()
    }
  }

  return {
    menuHide,
    menuShow,
    menuToggle
  }
}
