const _xs = ["0.75rem",{"lineHeight":"1.3333333333333333/* 16px */"}]
const _sm = ["0.875rem",{"lineHeight":"1.4285714285714286/* 20px */"}]
const _base = ["1rem",{"lineHeight":"1.5/* 24px */"}]
const _lg = ["1.125rem",{"lineHeight":"1.5555555555555556/* 28px */"}]
const _xl = ["1.25rem",{"lineHeight":"1.4/* 28px */"}]
const _2xl = ["1.5rem",{"lineHeight":"1.3333333333333333/* 32px */"}]
const _3xl = ["1.875rem",{"lineHeight":"1.2/* 36px */"}]
const _4xl = ["2rem",{"lineHeight":"1.4375/* 46px */"}]
const _5xl = ["3rem",{"lineHeight":"1.2916666666666667/* 62px */"}]
const _6xl = ["3.25rem",{"lineHeight":"1.25/* 65px */"}]
const _7xl = ["3.5rem",{"lineHeight":"1.3392857142857142/* 75px */"}]
const _8xl = ["4rem",{"lineHeight":"1.359375/* 87px */"}]
const _9xl = ["5.5rem",{"lineHeight":"1.0909090909090908/* 96px */"}]
const config = { "xs": _xs, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "8xl": _8xl, "9xl": _9xl,  }
export { config as default, _xs, _sm, _base, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _8xl, _9xl }