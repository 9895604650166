const _transparent = "transparent"
const _current = "currentColor"
const _black = "#000000"
const _white = "#ffffff"
const _dark = "#292E3D"
const _disabled = "#A3A3A3"
const _red = "#FF0000"
const _neutral = {"50":"#fafafa","100":"#f5f5f5","200":"#e5e5e5","300":"#d4d4d4","400":"#a3a3a3","500":"#737373","600":"#525252","700":"#404040","800":"#262626","900":"#171717","950":"#0a0a0a"}
const _blue = {"50":"#EAEDEF","100":"#C1C8D0","200":"#98A3B1","300":"#6F7F92","400":"#5A6D82","500":"#465A73","600":"#314863","700":"#26374A","800":"#1D2B3D","900":"#151F2B","950":"#101720","DEFAULT":"#314863"}
const _orange = {"50":"#FDEFD9","100":"#FBDEB2","200":"#F9C980","300":"#F7B34D","400":"#F5A833","500":"#F39200","600":"#D68000","700":"#B96F00","800":"#985B00","900":"#704300","950":"#3C2400","DEFAULT":"#F39200"}
const _gray = {"50":"#F8F8F7","100":"#F1F0EF","200":"#E9E9E6","300":"#E2E1DE","400":"#DBDAD6","500":"#B7B5AD","600":"#9B9A95","700":"#878680","800":"#6C6C69","900":"#4D4D4A","950":"#353533"}
const _DEFAULT = "#E9E9E6"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "dark": _dark, "disabled": _disabled, "red": _red, "neutral": _neutral, "blue": _blue, "orange": _orange, "gray": _gray, "DEFAULT": _DEFAULT, "light-gray": {"50":"#F7F8F6","100":"#EEF0EC","200":"#EAEDE7","300":"#DDE1D9","400":"#D9DED5","500":"#D5DAD0","600":"#C4C8BF","700":"#A6A8A4","800":"#848781","900":"#5F625D","950":"#474846","DEFAULT":"#D5DAD0"},  }
export { config as default, _transparent, _current, _black, _white, _dark, _disabled, _red, _neutral, _blue, _orange, _gray, _DEFAULT }