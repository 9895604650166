<template>
  <div v-if="data">
    <NuxtLayout :data="data">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useBlogStore } from '~/stores/BlogStore'
import { useShopStore } from '~/stores/ShopStore'

const appConfig = useAppConfig()

const store = useBlogStore()
const shopStore = useShopStore()

const { locale } = useI18n()
const snipcart = useSnipcart()

const { data } = await useAsyncGql('app', {
  // @ts-ignore
  locale,
  options: {
    initialCache: false
  }
})

if (data.value) {
  store.pushCategories(data?.value!.allCategories)
  shopStore.pushCategories(data?.value!.allShopCategories)
}

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk}` : appConfig.title
  }
})

watch(snipcart.isReady, () => {
  if (snipcart.isReady.value) {
    snipcart.setLanguage(locale.value)
  }
})
</script>
