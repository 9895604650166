import VueGtag from 'vue-gtag-next'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  nuxtApp.vueApp.use(VueGtag, {
    isEnabled: false,
    property: {
      id: config.public.gtag,
      params: {
        send_page_view: true
      }
    },
    useDebugger: true
  })
})
