import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_WPDdiystzB from "/opt/build/repo/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import plugin_lzubr1MIjF from "/opt/build/repo/node_modules/@nuxtjs/snipcart/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/opt/build/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_jBToDIZpFa from "/opt/build/repo/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gql_error_zbgPDv3vAX from "/opt/build/repo/plugins/gql-error.ts";
import lite_youtube_embed_client_M5n9wqzn0P from "/opt/build/repo/plugins/lite-youtube-embed.client.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import vue_gtag_client_CYt6toK02h from "/opt/build/repo/plugins/vue-gtag.client.ts";
import vue_gtm_client_stBjLl0OeM from "/opt/build/repo/plugins/vue-gtm.client.ts";
import vue_uid_zuF101RSew from "/opt/build/repo/plugins/vue-uid.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_WPDdiystzB,
  plugin_lzubr1MIjF,
  plugin_t2GMTTFnMT,
  plugin_jBToDIZpFa,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  gql_error_zbgPDv3vAX,
  lite_youtube_embed_client_M5n9wqzn0P,
  sentry_client_shVUlIjFLk,
  vue_gtag_client_CYt6toK02h,
  vue_gtm_client_stBjLl0OeM,
  vue_uid_zuF101RSew
]