export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"format-detection","content":"telephone=no"},{"name":"theme-color","content":"#000000"}],"link":[{"rel":"icon","type":"image/png","href":"/images/favicon.png"},{"rel":"apple-touch-icon","href":"/images/icon-192x192.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"preconnect","href":"https://www.datocms-assets.com"}],"style":[],"script":[],"noscript":[]}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appCdnURL = "/netlify/6735b1a95a01410008d91866--eurol.netlify.app"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false